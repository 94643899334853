/*******************************************
 * HOMEPAGE
 *******************************************/
.hero {
    font-family: "Open Sans", sans-serif;
    color: #53565a;
}
.hero span {
    font-size: 24px;
}

.hero .form-control-feedback {
    margin-right: 0px;
}

.hero a {
    font-weight: 600;
}
.hero.bg {
    background: no-repeat url('./images/login_bg.svg'), linear-gradient(119deg, #29978e, #34bdb2 52%, #bff7f3); /* IE >= 9*/
    background-size: 50% auto, cover;
    background-position: -7em 4em, 0 0;
    height: 530px;
}

@media (max-width: 1023px) {
.hero.bg {
        background-size: 100% auto, cover;
    }
}
@media (max-width: 767px) {
.hero.bg {
        background-size: 150% auto, cover;
    }
}
.hero .main-text {
    margin-top: 20%;
    color: #FFF;
}
.hero h1, .hero h2 {
    color: #FFF;
    font-weight: 200;
    line-height: 1.5em;
    letter-spacing: .05em;
}
.hero h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
}
.hero h2 {
    font-size: 20px;
    font-weight: 300;
}
.hero .btn-transparent {
    font-size: 18px;
    color: #FFF;
    border-color: #FFF;
    padding-left: 30px;
    padding-right: 30px;
}

.hero .btn[disabled=disabled] {
    background-color: #a8dbd7;
    border-color: #79c4be;
}

.hero .btn-transparent:hover {
    background: #2dd2c7;
}
.hero .big-tooltip,
#forgotPasswordPage .big-tooltip,
.hero .big-tooltip, .launch-big-tooltip {
    position: relative;
    display: block;
    margin-top: 40px;
    width: 100%;
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 0.05em;
    margin-bottom: 30px;
}
.hero .big-tooltip span, .launch-big-tooltip span, #forgotPasswordPage .big-tooltip span{
    width: 100%;
    display: block;
    color: #FFFFFF;
    background: #f4856f;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 5px;
    -webkit-box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 1px 12px rgba(0, 0, 0, 0.3);
}
.hero .big-tooltip span:after, .launch-big-tooltip span:after, #forgotPasswordPage .big-tooltip span:after {
    content: '';
    position: absolute;
    display: block;
    top: 100%;
    left: 47%;
    width: 0; height: 0;
    border-top: 15px solid #f4856f;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, .2));
}

.teal-title {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #29978e;
    margin-bottom: 1em;
}

#resetPasswordForm .marginBottomS {
    margin-bottom: 30px;
}

.hero a:hover {
    text-decoration: none;
}

.hero .panel {
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: none;
}

.hero .panel-footer {
    border-color: #e2e2e3;
    background-color: #fff;
    padding: 20px;
    text-align: center;
}

.hero .panel-footer.teal-100-bg {
    background-color: #eaf8f7;
    border-top: 1px solid #ade4e0;
    font-size: 20px;
}

.hero .panel-footer p {
    margin: 0;
}

.hero .control-group .control-label,
.hero .control-group .controls {
    display: inline-block;
}
.hero .control-group .control-label {
    width: 30%;
}

.hero .control-group {
    margin-bottom: .4em;
}
.hero .offset-label,
.hero .control-group .offset-label {
    margin-left: 30%;
}
.hero .control-group .control-label {
    width: 30%;
    color: #53565a;
}
.hero .control-group .controls {
    width: 59%;
}
@media (max-width: 767px) {
.hero .control-group .control-label,
.hero .control-group .controls {
        width: 100%;
    }
.hero .offset-label, .hero .control-group .offset-label {
        margin-left: 0;
    }
}

.hero .btn-default {
    font-size: 22px;
    width: 100%;
}

.hero #submitBtn {
    min-width: 150px;
    margin-left: -2px;
}

.hero .form-bottom-margin {
    margin-bottom: .8em;
}

.hero .form-bottom-medium-margin {
    margin-bottom: 10px;
}

.hero .help-block {
    margin-bottom: 0;
    margin-top: 0;
}

.hero .login-alignment {
    margin-left: 3px;
}


/*******************************************
 * PANEL
 *******************************************/
.panel, .panel-group .panel {
    border: 1px solid #e2e2e3;
    border-radius: 0px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, .05);
}
.panel-heading {
    border-radius: 0px;
}
.panel.panel-rounded,
.panel-group .panel.panel-rounded {
    border-radius: 4px;
}
.panel-rounded .panel-heading {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.panel-rounded .panel-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.panel-group a:hover, .panel-group a:focus,  .panel-group a:active {
    text-decoration: none;
    color: #53565a;
}
.panel-title h4 {
    color: #53565a;
    font-weight: 500;
    font-size: 16px;
}
.panel {
    border-radius: 0px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, .05);
}

.panel-yellow {
    border: #ffca28;
    color: #917710;
}

.panel-yellow .panel-heading {
    background-color: #f1c40f;
}

.panel-yellow .panel-body{
    background-color: #edd265;
    border-radius: 0 0 3px 3px;
    color: #917710;
}

.panel-gray {
    border: #c0c0c0;
}

.panel-gray .panel-heading{
    background-color: #d9d9d9;
    border: 1px solid transparent;
}

.panel-gray .panel-body{
    background-color: #e2e2e3;
    border-radius: 0 0 3px 3px;
}

.panel-teal {
    border: transparent;
    background-color: #34bdb2;
}
.panel-teal .panel-heading {
    border: transparent;
    background-color: #34bdb2;
    color: #FFFFFF;
    text-align: center;
}
.panel.panel-teal .panel-heading .panel-title {
    color: #fff;
    text-align: center;
}

.panel-teal .panel-body{
    color: #fff;
    border: transparent;
    background-color: #37c8bc;
    text-align: center;
}

.panel-teal .btn{
    background: transparent;
    border-color: #fff;
    color: #fff;
}
.panel-teal .btn:hover {
    border-color: #FFFFFF;
    background: rgba(255,255, 255, 0.2);
}

.panel-teal table{
    color: #FFFFFF;
    border-radius: 0 0 3px 3px;
}

.fill {
    width: 100%;
    height: 100%;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}