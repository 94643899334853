// BRANDING
$main-color: #5eb5f8;
$text-color: #fefdff;

// DARK THEME

// LEFTBAR
$bar-color: #363644;
$hover-color: #ff8e9a;
$inactive-color: #0e7490;

// PANEL
$panel-color: #373a46;
$elements-color: #3c3c4d;
$searchbar-color: #3c3c4d;
$searchbarText-color: #8c8d96;
$dev-color: #f5427b;

// LIGHT THEME

$light-bar-color: #e2e1e8;
$light-panel-color: #edebf8;
$light-inactive-color: #999eaf;
$light-elements-color: #DDDBE7;

$border-radius-comment: 1.5rem;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type=number] {
    -moz-appearance: textfield; }
@keyframes show-popup {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.7);
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0; }

  45% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
    opacity: 1; }

  80% {
    -webkit-transform: translate(-50%, -50%) scale(0.95);
    transform: translate(-50%, -50%) scale(0.95); }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1); } }


.Chart {
    width: 80%;
    height: 90%;
    padding: 10px;
    position: relative;
    @media only screen and (max-width: 900px) {
        width: 100%;
        height: 80%; }
    @media only screen and (max-width: 900px) {
        width: 100%;
        height: 70%; }
    &__info {
        width: 100%; }
    &__timers {
        //position: absolute
        //top: -20px
        //right: 20px
        color: white;
        transition: all .5s;
        cursor: pointer;
        display: flex;
        //h6
        //    padding: 5px 10px
        //    border-radius: 10px
        //    background-color: $elements-color
        //    margin: 0 3px
        //    transition: all .5s
        //    &:hover
        //        background-color: $inactive-color
        .active {
            background-image: linear-gradient(157.52deg, #0067B0 9.08%, #0067B0 9.09%, #39D5FF 91.93%);
            color: #edf2f9; } } }

.comment-box-container textarea {
  border: none;
  resize: none; }


.comment-box-container textarea::placeholder {
  color: var(--gray); }


.comment-box-container label {
  height: 0;
  width: 0;
  visibility: hidden; }

.comment-box-container button {
  cursor: pointer; }

.comment-box-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.comment-box-container button[type="submit"] {
  //background: linear-gradient(157.52deg, #0067B0 9.08%, #0067B0 9.09%, #39D5FF 91.93%)
  color: var(--white);
  border-radius: $border-radius-comment; }

.comment-box-container-title {
  flex-wrap: wrap;
  //max-width: 400px
  margin: 10px auto; }

.comment-list {
  flex-wrap: wrap;
  //max-width: 400px
  //margin: 20px auto
  //background: var(--white)
  border-radius: $border-radius-comment;
  //box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
  overflow: hidden;
  padding: 14px; }

.comment-box {
  display: flex;
  flex-wrap: wrap;
  //max-width: 400px
  margin: 20px auto;
  background: #FFFFFF0D;
  border-radius: $border-radius-comment;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 14px;
  transition: min-height var(--transition);
  max-height: 46px; }

.comment-box-container .header {
  transition: opacity var(--transition) 0.2s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-40px); }

.comment-box-container .user {
  display: flex;
  align-items: center; }

.comment-field {
  color: var(--black);
  background: transparent;
  transition: transform var(--transition);
  font-size: 18px;
  width: 100%;
  outline: none;
  padding: 0;
  min-height: 60px;
  transform: translateY(-32px);
  line-height: 1; }

.comment-box.expanded .comment-field {
  transform: translateY(40px); }

.comment-box.expanded .header {
  transform: translateY(10px);
  opacity: 1;
  visibility: visible; }

.comment-box-container .actions {
  width: 100%;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  margin-top: 50px;
  opacity: 0;
  transition: opacity var(--transition) 0.2s; }

.comment-box.expanded .actions {
  opacity: 1; }

.comment-box-container .actions button {
  margin-left: 6px;
  font-size: 14px;
  padding: 12px;
  border: none;
  line-height: 1; }

.comment-box-container .actions .cancel {
  background: none; }

.comment-box.modified {
  max-height: unset; }

.news-container, .comment-box {
  vertical-align: top; }

