
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind custom colors - start */
:root {
  --light: #edf2f9;
  --dark-custom: #152e4d;
  --darker: #12263f;

  /*  */
  --color-facebook-lighter: #4e71ba;
  --color-facebook: #3b5998;
  /*  */
  --color-primary: var(--color-cyan);
  --color-primary-50: var(--color-cyan-50);
  --color-primary-100: var(--color-cyan-100);
  --color-primary-light: #3592D4;
  --color-primary-lighter: #39D5FF;
  --color-primary-dark: var(--color-cyan-dark);
  --color-primary-darker: var(--color-cyan-darker);
  --color-primary-purple: #90A0C8;
  --color-primary-pink: #FD2387;
  --color-primary-green: #26FFA4;
  --color-primary-yellow-dark: #FF843E;
  --color-primary-yellow: #FFE768;
  --color-blue-text: #4178FF;

  /*  */
  --color-green: #16a34a;
  --color-green-50: #f0fdf4;
  --color-green-100: #dcfce7;
  --color-green-light: #22c55e;
  --color-green-lighter: #4ade80;
  --color-green-dark: #15803d;
  --color-green-darker: #166534;
  /*  */
  --color-blue: #2563eb;
  --color-blue-50: #eff6ff;
  --color-blue-100: #dbeafe;
  --color-blue-light: #3b82f6;
  --color-blue-lighter: #60a5fa;
  --color-blue-dark: #1d4ed8;
  --color-blue-darker: #1e40af;
  /*  */
  --color-cyan: #0891b2;
  --color-cyan-50: #ecfeff;
  --color-cyan-100: #cffafe;
  --color-cyan-light: #06b6d4;
  --color-cyan-lighter: #22d3ee;
  --color-cyan-dark: #0e7490;
  --color-cyan-darker: #155e75;
  /*  */
  --color-teal: #0d9488;
  --color-teal-50: #f0fdfa;
  --color-teal-100: #ccfbf1;
  --color-teal-light: #14b8a6;
  --color-teal-lighter: #2dd4bf;
  --color-teal-dark: #0f766e;
  --color-teal-darker: #115e59;
  /*  */
  --color-fuchsia: #c026d3;
  --color-fuchsia-50: #fdf4ff;
  --color-fuchsia-100: #fae8ff;
  --color-fuchsia-light: #d946ef;
  --color-fuchsia-lighter: #e879f9;
  --color-fuchsia-dark: #a21caf;
  --color-fuchsia-darker: #86198f;
  /*  */
  --color-violet: #7c3aed;
  --color-violet-50: #f5f3ff;
  --color-violet-100: #ede9fe;
  --color-violet-light: #8b5cf6;
  --color-violet-lighter: #a78bfa;
  --color-violet-dark: #6d28d9;
  --color-violet-darker: #5b21b6;
}
/* Tailwind custom colors - end */

input:focus:required:invalid,
textarea:focus:required:invalid {
  @apply tw-ring tw-ring-danger-light dark:tw-ring-danger;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*color: #0a73cc;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  /*color: #53565a;*/
  /*font-weight: bold;*/
  /*text-decoration: none;*/
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/*.active {*/
/*  color: var(--color-primary-lighter);*/
/*}*/

/*.footer {*/
/*  padding-top: 40px;*/
/*  min-height: calc(100vh - 92px);*/
/*}*/

.border-blue {
  border-radius: 8px;
  /*border: 1px solid #0a73cc;*/
}

.padding-form {
  padding: 50px;
  box-shadow: 0 0 12px #ccc;
}

.not-found {
  padding-top: 100px;
  text-align: center;
}

.form-layout {
  padding: 50px 0;
  align-content: center;
}

.small-font {
  font-size: 14px;
}

.graph-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  /*width: 800px;*/
  /*height: 800px;*/
  background-color: #fff;
}

.st-panel {
  padding: 20px;
  box-shadow: 0 0 12px #ccc;
  border-radius: 8px;
}

.st-margin-row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.st-title {
  color: #53565a;
  font-weight: bold;
  font-size: 15px;
}

.st-text-grey {
  color: #a0a1a2;
}

.st-text-align-left {
  text-align: left;
}

/* overwrite InfiniteScroll width */
.infinite-scroll-component__outerdiv {
  min-width: 100% !important;
}

/* overwrite bootstrap table x overflow */
.react-bootstrap-table table {
  table-layout: auto !important;
}

.pink {
  border-bottom-color: var(--color-primary-pink);
  color: white !important;
  background-color: var(--color-primary-pink) !important;
}

.diamond {
  color: #0e7490;
  background-color: #26FFA4;
  margin: -25px auto;
  vertical-align: middle;
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 6px;
  transform: rotate(
          45deg
  );
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(
          45deg
  );
  position: relative;

}
.diamond span{
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari and Chrome */
  position:absolute;
  top: 14px;
  right: 4px;
}

li.checkmark { background:url('images/checkmark.svg') no-repeat 0 50% !important; }

