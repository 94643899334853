.App {
  /*text-align: center;*/
}

.App-logo {
  /*height: 10vmin;*/
  width: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

@media (max-width: 500px) {
  .react-bootstrap-table table {
    table-layout: auto !important;
  }

}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a, a:hover, a:visited, a:link, a:active {
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  /*color: inherit !important;*/
}

:focus, :focus-visible, :focus-within, :active, :visited {
  outline: none;
}

dl, ol, ul {
  margin-top: 0 !important;;
  margin-bottom: 0 !important;
}

dd {
  margin-bottom: 0 !important;;
}

/*Overwriting Bootstrap styling*/
.modal-content {
  background-color: transparent;
  border: none;
  display: block;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: inherit #536082;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: inherit;
}

*::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #536082;
}