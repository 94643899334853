
.header {
    /*background-color: white;*/
    /*min-height: 80px;*/
    /*line-height: 80px;*/
    align-content: center;
    display: inline;
    text-align: right;
    vertical-align: center;
    border-bottom: 1px solid #ececec;
}

.header .navbar {
    min-height: 80px;
    border-bottom: 1px solid #ececec;
}

.btn-transparent-teal {
    color: #34bdb2 !important;
    font-weight: 600;
    padding: 5px 15px;
    background: transparent;
    border: 1px solid #34bdb2;
    border-radius: 4px;
}

.btn-transparent-teal:hover {
    color: #34BDB2 !important;
    border: 1px solid #34BDB2 !important;
    background-color: #EAF8F7 !important;
}
.btn-transparent-teal:active, .btn-transparent-teal:visited {
    color: #34BDB2 !important;
    border: 1px solid #34BDB2 !important;
    background-color:#ADE4E0 !important;
}